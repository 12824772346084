import { AttendanceSummaryFixFormHeaderType } from "@/components/AttendanceSummaryFixForm.vue";

export class AttendanceSummaryFix {
  worktime: number;
  workdays: number;
  salary1: number;
  salary2: number;
  salary3: number;
  salary4: number;
  overtime: number;
  inovertime: number;
  inoverallowance: number;
  overallowance: number;
  midnighttime: number;
  midnightallowance: number;
  holidaytime: number;
  holidayallowance: number;
  paiddays: number;
  paidallowance: number;
  latecount: number;
  earlycount: number;
  absencetime: number;
  otherallowance1: number;
  otherallowance2: number;
  nightallowance: number;
  perfectallowance: number;
  yearendallowance: number;
  commutingdistance: number;
  commutingallowance: number;
  mealbill: number;
  commutingworkcount: number;
  commutingworkallowance: number;
  parkingdailyallowance: number;
  nightguardcount: number;
  nightguardallowance: number;
  emergencycount: number;
  emergencyallowance: number;
  midnighttelcount: number;
  midnighttelallowance: number;
  specialvacationcount: number;
  specialvacationallowance: number;
  welfarekaigo: number;
  welfaresyogai: number;
  minDispFlag: number;
  nightcount: number;

  constructor(_props?: {
    worktime: number;
    workdays: number;
    salary1: number;
    salary2: number;
    salary3: number;
    salary4: number;
    overtime: number;
    inovertime: number;
    inoverallowance: number;
    overallowance: number;
    midnighttime: number;
    midnightallowance: number;
    holidaytime: number;
    holidayallowance: number;
    paiddays: number;
    paidallowance: number;
    latecount: number;
    earlycount: number;
    absencetime: number;
    otherallowance1: number;
    otherallowance2: number;
    nightallowance: number;
    perfectallowance: number;
    yearendallowance: number;
    commutingdistance: number;
    commutingallowance: number;
    mealbill: number;
    commutingworkcount: number;
    commutingworkallowance: number;
    parkingdailyallowance: number;
    nightguardcount: number;
    nightguardallowance: number;
    emergencycount: number;
    emergencyallowance: number;
    midnighttelcount: number;
    midnighttelallowance: number;
    specialvacationcount: number;
    specialvacationallowance: number;
    welfarekaigo: number;
    welfaresyogai: number;
    minDispFlag: number;
    nightcount: number;
  }) {
    if (_props) {
      this.worktime = _props.worktime;
      this.workdays = _props.workdays;
      this.salary1 = _props.salary1;
      this.salary2 = _props.salary2;
      this.salary3 = _props.salary3;
      this.salary4 = _props.salary4;
      this.overtime = _props.overtime;
      this.inovertime = _props.inovertime;
      this.inoverallowance = _props.inoverallowance;
      this.overallowance = _props.overallowance;
      this.midnighttime = _props.midnighttime;
      this.midnightallowance = _props.midnightallowance;
      this.holidaytime = _props.holidaytime;
      this.holidayallowance = _props.holidayallowance;
      this.paiddays = _props.paiddays;
      this.paidallowance = _props.paidallowance;
      this.latecount = _props.latecount;
      this.earlycount = _props.earlycount;
      this.absencetime = _props.absencetime;
      this.otherallowance1 = _props.otherallowance1;
      this.otherallowance2 = _props.otherallowance2;
      this.nightallowance = _props.nightallowance;
      this.perfectallowance = _props.perfectallowance;
      this.yearendallowance = _props.yearendallowance;
      this.commutingdistance = _props.commutingdistance;
      this.commutingallowance = _props.commutingallowance;
      this.mealbill = _props.mealbill;
      this.commutingworkcount = _props.commutingworkcount;
      this.commutingworkallowance = _props.commutingworkallowance;
      this.parkingdailyallowance = _props.parkingdailyallowance;
      this.nightguardcount = _props.nightguardcount;
      this.nightguardallowance = _props.nightguardallowance;
      this.emergencycount = _props.emergencycount;
      this.emergencyallowance = _props.emergencyallowance;
      this.midnighttelcount = _props.midnighttelcount;
      this.midnighttelallowance = _props.midnighttelallowance;
      this.specialvacationcount = _props.specialvacationcount;
      this.specialvacationallowance = _props.specialvacationallowance;
      this.welfarekaigo = _props.welfarekaigo;
      this.welfaresyogai = _props.welfaresyogai;
      this.minDispFlag = _props.minDispFlag;
      this.nightcount = _props.nightcount;
      return;
    }
    this.worktime = 0;
    this.workdays = 0;
    this.salary1 = 0;
    this.salary2 = 0;
    this.salary3 = 0;
    this.salary4 = 0;
    this.overtime = 0;
    this.inovertime = 0;
    this.inoverallowance = 0;
    this.overallowance = 0;
    this.midnighttime = 0;
    this.midnightallowance = 0;
    this.holidaytime = 0;
    this.holidayallowance = 0;
    this.paiddays = 0;
    this.paidallowance = 0;
    this.latecount = 0;
    this.earlycount = 0;
    this.absencetime = 0;
    this.otherallowance1 = 0;
    this.otherallowance2 = 0;
    this.nightallowance = 0;
    this.perfectallowance = 0;
    this.yearendallowance = 0;
    this.commutingdistance = 0;
    this.commutingallowance = 0;
    this.mealbill = 0;
    this.commutingworkcount = 0;
    this.commutingworkallowance = 0;
    this.parkingdailyallowance = 0;
    this.nightguardcount = 0;
    this.nightguardallowance = 0;
    this.emergencycount = 0;
    this.emergencyallowance = 0;
    this.midnighttelcount = 0;
    this.midnighttelallowance = 0;
    this.specialvacationcount = 0;
    this.specialvacationallowance = 0;
    this.welfarekaigo = 0;
    this.welfaresyogai = 0;
    this.minDispFlag = 0;
    this.nightcount = 0;
  }
}

export const AttendanceSummaryFixHeader: AttendanceSummaryFixFormHeaderType[] = [
  {
    value: "worktime",
    text: "勤務時間",
    icon: "mdi-account-clock",
    suffix: ""
  },
  {
    value: "workdays",
    text: "勤務日数",
    icon: "mdi-calendar-account-outline",
    suffix: "日"
  },
  {
    value: "salary1",
    text: "基本給",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "salary2",
    text: "基本給2",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "salary3",
    text: "基本給3",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "salary4",
    text: "基本給4",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "inovertime",
    text: "時間内残業",
    icon: "mdi-clock-time-one-outline",
    suffix: "時間"
  },
  {
    value: "inoverallowance",
    text: "時間内手当",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "overtime",
    text: "時間外残業",
    icon: "mdi-clock-time-one-outline",
    suffix: "時間"
  },
  {
    value: "midnighttime",
    text: "深夜残業",
    icon: "mdi-clock-time-one-outline",
    suffix: "時間"
  },
  {
    value: "midnightallowance",
    text: "深夜手当",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "holidaytime",
    text: "休日残業",
    icon: "mdi-clock-time-one-outline",
    suffix: "時間"
  },
  {
    value: "holidayallowance",
    text: "休日手当",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "paiddays",
    text: "有給日数",
    icon: "mdi-calendar-account-outline",
    suffix: "日"
  },
  {
    value: "paidallowance",
    text: "有休手当",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "latecount",
    text: "遅刻回数",
    icon: "mdi-counter",
    suffix: "回"
  },
  {
    value: "earlycount",
    text: "早退回数",
    icon: "mdi-counter",
    suffix: "回"
  },
  {
    value: "absencetime",
    text: "欠勤日数",
    icon: "mdi-clock-time-one-outline",
    suffix: "日"
  },
  {
    value: "nightallowance",
    text: "夜勤手当",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "perfectallowance",
    text: "皆勤手当",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "yearendallowance",
    text: "年末年始手当",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "otherallowance1",
    text: "その他支給1",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "otherallowance2",
    text: "その他支給2",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "commutingdistance",
    text: "通勤距離",
    icon: "mdi-map-marker-distance",
    suffix: "km"
  },
  {
    value: "commutingallowance",
    text: "ガソリン代",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "mealbill",
    text: "食事代",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "nightcount",
    text: "夜勤回数",
    icon: "mdi-counter",
    suffix: "回"
  },
  {
    value: "commutingworkcount",
    text: "通勤日数",
    icon: "mdi-calendar-account-outline",
    suffix: "日"
  },
  {
    value: "commutingworkallowance",
    text: "通勤日額",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "parkingdailyallowance",
    text: "駐車場日額",
    icon: "mdi-wallet",
    suffix: "円"
  },
  {
    value: "specialvacationcount",
    text: "特休回数",
    icon: "mdi-counter",
    suffix: "回"
  },
  {
    value: "specialvacationallowance",
    text: "特休手当",
    icon: "mdi-counter",
    suffix: "円"
  }
];
