export default class AttendanceDetail {
  recordid: string;
  employee: string;
  affiliation: string;
  shiftid: string;
  shiftpatternid: string;
  datestr: string;
  date: string;
  start: string;
  end: string;
  distance: string;
  inover: string;
  lateleave: string;
  amount: string;
  breakflg: string;
  daycount: string;
  nightcount: string;
  hadmeal: string;
  careShu: string;
  careKbn: string;
  timeKbn: string;
  constructor(
    recordid: string,
    employee: string,
    affiliation: string,
    shiftid: string,
    shiftpatternid: string,
    datestr: string,
    date: string,
    start: string,
    end: string,
    distance: string,
    inover: string,
    lateleave: string,
    amount: string,
    breakflg: string,
    daycount: string,
    nightcount: string,
    hadmeal: string,
    careShu: string,
    careKbn: string,
    timeKbn: string
  ) {
    this.recordid = recordid;
    this.employee = employee;
    this.affiliation = affiliation;
    this.shiftid = shiftid;
    this.shiftpatternid = shiftpatternid;
    this.datestr = datestr;
    this.date = date;
    this.start = start;
    this.end = end;
    this.distance = distance;
    this.inover = inover;
    this.lateleave = lateleave;
    this.amount = amount;
    this.breakflg = breakflg;
    this.daycount = daycount;
    this.nightcount = nightcount;
    this.hadmeal = hadmeal;
    this.careShu = careShu;
    this.careKbn = careKbn;
    this.timeKbn = timeKbn;
  }
}
