/**
 * 管理者勤務表画面からの出退勤実績登録・更新用
 */
export default class AttendanceFix {
  recordId: string;
  employeeId: string;
  affiliationId: string;
  targetShiftId: string;
  shiftpatternid: string;
  attendanceDate: string;
  startTime: string;
  endTime: string;
  movingDistance: string;
  breaktime: string;
  work: string;
  over: string;
  midnight: string;
  inover: string;
  lateleave: string;
  hadmeal: string;
  workclassificationid: string;
  amount: string;
  reserveid1: string;
  nightShift: string;
  careShu: string;
  careKbn: string;
  timeKbn: string;
  nightGuard: string;
  emergencyFlag: string;
  midnightTel: string;
  absence: string;
  constructor(
    recordId: string,
    employeeId: string,
    affiliationId: string,
    targetShiftId: string,
    shiftpatternid: string,
    attendanceDate: string,
    startTime: string,
    endTime: string,
    movingDistance: string,
    breaktime: string,
    work: string,
    over: string,
    midnight: string,
    inover: string,
    lateleave: string,
    hadmeal: string,
    workclassificationid: string,
    amount: string,
    reserveid1: string,
    nightShift: string,
    careShu: string,
    careKbn: string,
    timeKbn: string,
    nightGuard: string,
    emergencyFlag: string,
    midnightTel: string,
    absence: string
  ) {
    this.recordId = recordId;
    this.employeeId = employeeId;
    this.affiliationId = affiliationId;
    this.targetShiftId = targetShiftId;
    this.shiftpatternid = shiftpatternid;
    this.attendanceDate = attendanceDate;
    this.startTime = startTime;
    this.endTime = endTime;
    this.movingDistance = movingDistance;
    this.breaktime = breaktime;
    this.work = work;
    this.over = over;
    this.midnight = midnight;
    this.inover = inover;
    this.lateleave = lateleave;
    this.hadmeal = hadmeal;
    this.workclassificationid = workclassificationid;
    this.amount = amount;
    this.reserveid1 = reserveid1;
    this.nightShift = nightShift;
    this.careShu = careShu;
    this.careKbn = careKbn;
    this.timeKbn = timeKbn;
    this.nightGuard = nightGuard;
    this.emergencyFlag = emergencyFlag;
    this.midnightTel = midnightTel;
    this.absence = absence;
  }
}
