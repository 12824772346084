export class SalaryCalcKey {
  paymentdate: string;
  employeeId: string;
  affiliationId: string;

  constructor(_props: {
    paymentdate: string;
    employeeId: string;
    affiliationId: string;
  }) {
    this.paymentdate = _props.paymentdate;
    this.employeeId = _props.employeeId;
    this.affiliationId = _props.affiliationId;
  }
}

export class SalaryCalc extends SalaryCalcKey {
  worktime?: string | number;
  workdays?: string | number;
  salary1?: string | number;
  salary2?: string | number;
  salary3?: string | number;
  salary4?: string | number;
  inovertime?: string | number;
  overtime?: string | number;
  midnighttime?: string | number;
  holidaytime?: string | number;
  paiddays?: string | number;
  latecount?: string | number;
  earlycount?: string | number;
  absencetime?: string | number;
  nightcount?: string | number;
  commutingallowance?: string | number;
  commutingdistance?: string | number;
  holidayallowance?: string | number;
  paidallowance?: string | number;
  perfectallowance?: string | number;
  inoverallowance?: string | number;
  overallowance?: string | number;
  midnightallowance?: string | number;
  nightallowance?: string | number;
  otherallowance1?: string | number;
  otherallowance2?: string | number;
  yearendallowance?: string | number;
  mealbill?: string | number;
  commutingworkcount?: string | number;
  commutingworkallowance?: string | number;
  parkingdailyallowance?: string | number;
  nightguardcount?: string | number;
  nightguardallowance?: string | number;
  emergencycount?: string | number;
  emergencyallowance?: string | number;
  midnighttelcount?: string | number;
  midnighttelallowance?: string | number;
  specialvacationcount?: string | number;
  specialvacationallowance?: string | number;
  minDispFlag?: string | number;
  welfarekaigo?: string | number;
  welfaresyogai?: string | number;
  buWelfareKaigo?: string | number;
  buWelfareSyogai?: string | number;
  specialWelfareKaigo?: string | number;
  specialWelfareSyogai?: string | number;

  constructor(_props: {
    paymentdate: string;
    employeeId: string;
    affiliationId: string;
    worktime?: string | number;
    workdays?: string | number;
    salary1?: string | number;
    salary2?: string | number;
    salary3?: string | number;
    salary4?: string | number;
    inovertime?: string | number;
    overtime?: string | number;
    midnighttime?: string | number;
    holidaytime?: string | number;
    paiddays?: string | number;
    latecount?: string | number;
    earlycount?: string | number;
    absencetime?: string | number;
    nightcount?: string | number;
    commutingallowance?: string | number;
    commutingdistance?: string | number;
    holidayallowance?: string | number;
    paidallowance?: string | number;
    perfectallowance?: string | number;
    inoverallowance?: string | number;
    overallowance?: string | number;
    midnightallowance?: string | number;
    nightallowance?: string | number;
    otherallowance1?: string | number;
    otherallowance2?: string | number;
    yearendallowance?: string | number;
    mealbill?: string | number;
    commutingworkcount?: string | number;
    commutingworkallowance?: string | number;
    parkingdailyallowance?: string | number;
    nightguardcount?: string | number;
    nightguardallowance?: string | number;
    emergencycount?: string | number;
    emergencyallowance?: string | number;
    midnighttelcount?: string | number;
    midnighttelallowance?: string | number;
    specialvacationcount?: string | number;
    specialvacationallowance?: string | number;
    minDispFlag?: string | number;
    welfarekaigo?: string | number;
    welfaresyogai?: string | number;
    buWelfareKaigo?: string | number;
    buWelfareSyogai?: string | number;
    specialWelfareKaigo?: string | number;
    specialWelfareSyogai?: string | number;
  }) {
    super(_props);
    this.paymentdate = _props.paymentdate;
    this.employeeId = _props.employeeId;
    this.affiliationId = _props.affiliationId;
    this.worktime = _props.worktime;
    this.workdays = _props.workdays;
    this.salary1 = _props.salary1;
    this.salary2 = _props.salary2;
    this.salary3 = _props.salary3;
    this.salary4 = _props.salary4;
    this.inovertime = _props.inovertime;
    this.overtime = _props.overtime;
    this.midnighttime = _props.midnighttime;
    this.holidaytime = _props.holidaytime;
    this.paiddays = _props.paiddays;
    this.latecount = _props.latecount;
    this.earlycount = _props.earlycount;
    this.absencetime = _props.absencetime;
    this.nightcount = _props.nightcount;
    this.commutingallowance = _props.commutingallowance;
    this.commutingdistance = _props.commutingdistance;
    this.holidayallowance = _props.holidayallowance;
    this.paidallowance = _props.paidallowance;
    this.perfectallowance = _props.perfectallowance;
    this.inoverallowance = _props.inoverallowance;
    this.overallowance = _props.overallowance;
    this.midnightallowance = _props.midnightallowance;
    this.nightallowance = _props.nightallowance;
    this.otherallowance1 = _props.otherallowance1;
    this.otherallowance2 = _props.otherallowance2;
    this.yearendallowance = _props.yearendallowance;
    this.mealbill = _props.mealbill;
    this.commutingworkcount = _props.commutingworkcount;
    this.commutingworkallowance = _props.commutingworkallowance;
    this.parkingdailyallowance = _props.parkingdailyallowance;
    this.nightguardcount = _props.nightguardcount;
    this.nightguardallowance = _props.nightguardallowance;
    this.emergencycount = _props.emergencycount;
    this.emergencyallowance = _props.emergencyallowance;
    this.midnighttelcount = _props.midnighttelcount;
    this.midnighttelallowance = _props.midnighttelallowance;
    this.specialvacationcount = _props.specialvacationcount;
    this.specialvacationallowance = _props.specialvacationallowance;
    this.minDispFlag = _props.minDispFlag;
    this.welfarekaigo = _props.welfarekaigo;
    this.welfaresyogai = _props.welfaresyogai;
    this.buWelfareKaigo = _props.buWelfareKaigo;
    this.buWelfareSyogai = _props.buWelfareSyogai;
    this.specialWelfareKaigo = _props.specialWelfareKaigo;
    this.specialWelfareSyogai = _props.specialWelfareSyogai;
  }
}
