<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-monitor-edit"
    midium
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <AttendanceSummaryFixForm v-model="summary" :header="header" />
  </DialogBase>
</template>
<script>
import Vue from "vue";

import AttendanceSummaryFixForm from "@/components/AttendanceSummaryFixForm.vue";

// mixins
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import { SalaryCalc, SalaryCalcKey } from "@/models/SalaryCalc";
import {
  AttendanceSummaryFix,
  AttendanceSummaryFixHeader
} from "@/models/attendance/summary/AttendanceSummaryFix";

const attendanceSummaryFix = new AttendanceSummaryFix();

export default Vue.extend({
  name: "AttendanceSummaryFixDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  components: { AttendanceSummaryFixForm },
  data: () => ({
    header: AttendanceSummaryFixHeader,
    targetmonth: "",
    user: {
      incode: "",
      name: ""
    },
    summary: attendanceSummaryFix,
    edit: false
  }),
  computed: {
    Title() {
      const s = this.targetmonth.split("-");
      const title = s[0] + "年" + s[1] + "月 " + this.user.name + " 合計修正";
      return title;
    }
  },
  methods: {
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async complete() {
      console.log("complete");

      try {
        const key = new SalaryCalcKey({
          paymentdate: this.getPaymentDate(),
          employeeId: this.user.incode,
          affiliationId: "-999"
        });
        const payload = new SalaryCalc({
          ...key,
          ...this.summary
        });
        console.log("salaryfix", payload);
        if (this.edit) {
          await this.$put(this.Paths.attendanceSalaryFix, payload);
        } else {
          await this.$post(this.Paths.attendanceSalaryFix, payload);
        }

        this.$info("更新しました。");
        this.$close(true);
      } catch (e) {
        console.log("posterror", e);
        this.$error(e.message);
      }
    },
    getPaymentDate() {
      const ym = this.targetmonth.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]), 1);
      dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("000000" + Number(dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("000000" + dt.getDate()).slice(-2);

      return result;
    }
  },
  created() {
    console.log("created", this.args);
    this.targetmonth = this.args.targetmonth;
    this.user.incode = this.args.user.code;
    this.user.name = this.args.user.name;
    this.edit = this.args.edit;
    if (this.edit) this.summary = this.args.summary;
  },
  mounted() {
    console.log("mounted", this.args);
  }
});
</script>
